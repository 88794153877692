.primary-button {
    background-color: #86BDC9;
    border-radius: 5px;
    padding: 10px 15px;
    color: white;
    text-decoration: none;
}

.secondary-button {
    background-color: #e2e2e2;
    border-radius: 5px;
    border: #86BDC9;
    padding: 10px 15px;
    color: rgb(41, 41, 41);
    text-decoration: none;
}
