body {
  margin: 0;
  font-family: "Work Sans", "League Spartan", "Montserrat", "Open Sans", "BlinkMacSystemFont", 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.background-white {
  background-color: #E6DED3;
}

.btn-red {
  background-color: #DE5761;
  color: white;
  padding: 10px 30px;
  border-radius: 0%;
  box-shadow:  5px 5px 10px thistle;
}
