.bg-text {
    font-weight: bold;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -55%);
    z-index: 2;
    width: 100%; 
    text-align: center;
    background-size: cover;
  }

  .home-text {
    text-align: left;
    font-weight: bold;
    width: 50%;
    color: #494949;
  }

  .home-paragraph {
    margin-top: 20px;
    text-align: left;
    color: #494949;
  }

  .text-box {
    position: absolute;
    top: 35%;
    left: 0%;
    width: 50%;
    padding-left: 10%;
  }

  @media only screen and (max-width: 600px) {
    .text-box {
      position: absolute;
      top: 20%;
      left: 0%;
      width: 100%;
      padding: 10% ;
      /* background: rgb(255,255,255); */
      background: radial-gradient(circle, rgba(255,255,255,1) 10%, rgba(255,255,255,0) 80%);
    }

    .home-text-container {
      width: 50%;
      float: left;
      height: 0vh;
    }
  }

  .btn-margin {
    margin-top: 30px;
  }

  .citi-btn {
    background-color: #86BDC9;
    border-radius: 5px;
    padding: 10px 15px;
    color: white;
    text-decoration: none;
  }

  .citi-btn:hover {
    color: white;
    background-color: #8FC3CE;
    text-decoration: none;
  }
  

  .height-100{
    height: 90vh;
  }


  .mobile-back {
    background-image: url("./5.jpg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 90vh;
    display: flex;
  }

  @media only screen and (min-width: 600px) {
    .mobile-back {
      display: none;
    }
  }