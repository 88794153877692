.grey-image {
    filter: gray; /* IE6-9 */
    -webkit-filter: grayscale(1); /* Google Chrome, Safari 6+ & Opera 15+ */
    filter: grayscale(1); /* Microsoft Edge and Firefox 35+ */
    width: 150px;
}

.scroll { 
    padding: 30px 0px;
    display: flex;
    overflow: hidden;
    user-select: none;
    justify-content: space-between;
}

.scroll div  {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-shrink: 0;
    animation: scroll-horizontal 10s linear infinite;
}

@keyframes scroll-horizontal {
    to {
        transform: translateX(-100%);
    }
}