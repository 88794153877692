body {
  margin: 0;
  font-family: "Work Sans", "League Spartan", "Montserrat", "Open Sans", "BlinkMacSystemFont", 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.background-white {
  background-color: #E6DED3;
}

.btn-red {
  background-color: #DE5761;
  color: white;
  padding: 10px 30px;
  border-radius: 0%;
  box-shadow:  5px 5px 10px thistle;
}

.App {
  text-align: center;
  min-height: 100vh;
  scroll-behavior: smooth;
}



/* Style the navigation menu */
.topnav {
    color: #25313C;
    font-weight: 500;
    letter-spacing: 1px;
    transition: 0.3s;
  }
  .height100 {
    height: 80px;
  }

.text-big {
  font-size: x-large;
}
  
  /* Hide the links inside the navigation menu (except for logo/home) */
  .topnav #myLinks {
    transition: 0.3s;
  }
 
  /* Style navigation menu links */
  .topnav a {
    color: #25313C;
    padding: 14px 16px;
    text-decoration: none;
    display: block;
    transition: 0.3s;
    /* background-color: #242423; */
  }
  
  /* Style the hamburger menu */
  .topnav a.icon {
    /* background: #242423; */
    display: block;
    position: absolute;
    right: 0;
    top: 0;
  }

  .mt100 {
    margin-top: 80px;
  }
  
  /* Style the active link (or home/logo) */
  .active {
    /* background-color:#242423; */
    color: #25313C;
  }


  .navDiv {
    z-index: 999;
    position: fixed;
    top:0;
    width: 100%;
  }
   
  .menu {
    background-color: #E6DED3;
  }

  @media only screen and (max-width: 600px) { 
    /* Style the navigation menu */
    .topnav {
    color: #25313C;
    font-weight: 500;
    letter-spacing: 1px;
    transition: 0.3s;
    padding: 10px;
  }
}

.text-menu {
  text-transform: uppercase;
  color: #686868;
  font-size: 14px;
  font-weight: 600;
}
.bg-text {
    font-weight: bold;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -55%);
            transform: translate(-50%, -55%);
    z-index: 2;
    width: 100%; 
    text-align: center;
    background-size: cover;
  }

  .home-text {
    text-align: left;
    font-weight: bold;
    width: 50%;
    color: #494949;
  }

  .home-paragraph {
    margin-top: 20px;
    text-align: left;
    color: #494949;
  }

  .text-box {
    position: absolute;
    top: 35%;
    left: 0%;
    width: 50%;
    padding-left: 10%;
  }

  @media only screen and (max-width: 600px) {
    .text-box {
      position: absolute;
      top: 20%;
      left: 0%;
      width: 100%;
      padding: 10% ;
      /* background: rgb(255,255,255); */
      background: radial-gradient(circle, rgba(255,255,255,1) 10%, rgba(255,255,255,0) 80%);
    }

    .home-text-container {
      width: 50%;
      float: left;
      height: 0vh;
    }
  }

  .btn-margin {
    margin-top: 30px;
  }

  .citi-btn {
    background-color: #86BDC9;
    border-radius: 5px;
    padding: 10px 15px;
    color: white;
    text-decoration: none;
  }

  .citi-btn:hover {
    color: white;
    background-color: #8FC3CE;
    text-decoration: none;
  }
  

  .height-100{
    height: 90vh;
  }


  .mobile-back {
    background-image: url(/static/media/5.4d4c9d48.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 90vh;
    display: flex;
  }

  @media only screen and (min-width: 600px) {
    .mobile-back {
      display: none;
    }
  }
.image {
    width: 400px;
    position: absolute;
    top: 0px;
    left: 0px;
}

.image-top {
    width: 400px;
    position: absolute;
    top: 100px;
    left: 150px;
}

.image-top-right {
    width: 400px;
    position: absolute;
    top: 0px;
    left: 150px;
}

.image-bottom-right {
    width: 400px;
    position: absolute;
    top: 100px;
    left: 0px;
}

.service-card {
    width: 330px;
    background-color: white;
    box-shadow: 0px 1px 20px #ddd;
}

.other-service-card {
    background-color: white;
    box-shadow: 0px 1px 20px #ddd;
}
.portofolio-image {
    object-fit: scale-down;
    max-height: 350px;
    /* max-width: 50%; */
    transition-property: margin-top max-width;
    transition-timing-function: ease-in-out;
    transition-duration: 3s;
    position: relative;
    z-index: 1;
  }
  .transformed-state {
    margin-top: 0px;
    max-width: 100%;
    transition-property: margin-top max-width;
    transition-timing-function: ease-in-out;
    transition-duration: 3s;
  }
  
  .delay-1 {
    transition-delay: 0.5s;
  }

  .delay-2 {
    transition-delay: 1s;
  }

  .delay-3 {
    transition-delay: 1.5s;
  }

  .left-image {
    height: 250px;
    margin-right: -90px;
    opacity: 0.7;
  }

  .right-image {
    height: 250px;
    margin-left: -90px;
    opacity: 0.7;
  }

  .citi-btn {
    background-color: #86BDC9;
    border-radius: 5px;
    padding: 10px 15px;
    color: white;
    text-decoration: none;
  }

  .citi-btn:hover {
    color: white;
    background-color: #8FC3CE;
    text-decoration: none;
  }

  @media only screen and (max-width: 600px) { 
    /* Style the navigation menu */
    .portofolio-image {
      object-fit: scale-down;
      max-height: 350px;
      max-width: 90%;
      position: relative;
      z-index: 1;
    }
}
.primary-button {
    background-color: #86BDC9;
    border-radius: 5px;
    padding: 10px 15px;
    color: white;
    text-decoration: none;
}

.secondary-button {
    background-color: #e2e2e2;
    border-radius: 5px;
    border: #86BDC9;
    padding: 10px 15px;
    color: rgb(41, 41, 41);
    text-decoration: none;
}

.card-div {
    display: flex;
    align-items: stretch;
}

.testimonial-box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: white;
    width: 400px;
    border-radius: 10px;
    padding: 20px 30px;
    box-shadow: 5px 5px 50px #e2e2e2;
    min-height: 320px;
}

.star-div {
    padding: 10px 0px;
    height: 70px;
}

.star {
    margin: 5px;
    width: 30px;
    height: 30px;
}

.testimonial-text {
    display: flex;
    text-align: justify;
    padding: 0px 10px;
    margin-top: 5px;
    font-size: 14px;
    letter-spacing: 1px;
}

.testimonial-footer {
    display: flex;
    align-self: baseline;
    padding-left: 10px;
    align-items: center;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: bold;
    color: grey;
}

@-webkit-keyframes smallStar{
    0% {
        width: 10px;
        height: 10px;
    }

    100% {
        width: 30px;
        height: 30px;
    }
}

@keyframes smallStar{
    0% {
        width: 10px;
        height: 10px;
    }

    100% {
        width: 30px;
        height: 30px;
    }
}

@-webkit-keyframes smallStar-reverse{
     100% {
        width: 30px;
        height: 30px;
    }
    0% {
        width: 10px;
        height: 10px;
    }
}

@keyframes smallStar-reverse{
     100% {
        width: 30px;
        height: 30px;
    }
    0% {
        width: 10px;
        height: 10px;
    }
}

.star-animation {
    -webkit-animation: smallStar 3s;
            animation: smallStar 3s;
    -webkit-animation-delay: 0s;
            animation-delay: 0s;
    -webkit-animation-fill-mode: backwards;
            animation-fill-mode: backwards;
}


.star-animation-1 {
    -webkit-animation: smallStar 3s;
            animation: smallStar 3s;
    -webkit-animation-delay: 400ms;
            animation-delay: 400ms;
    -webkit-animation-fill-mode: backwards;
            animation-fill-mode: backwards;
}

.star-animation-2 {
    -webkit-animation: smallStar 3s;
            animation: smallStar 3s;
    -webkit-animation-delay: 800ms;
            animation-delay: 800ms;
    -webkit-animation-fill-mode: backwards;
            animation-fill-mode: backwards;
}

.star-animation-3 {
    -webkit-animation: smallStar 3s;
            animation: smallStar 3s;
    -webkit-animation-delay: 1200ms;
            animation-delay: 1200ms;
    -webkit-animation-fill-mode: backwards;
            animation-fill-mode: backwards;
}

.star-animation-4 {
    -webkit-animation: smallStar 3s;
            animation: smallStar 3s;
    -webkit-animation-delay: 1600ms;
            animation-delay: 1600ms;
    -webkit-animation-fill-mode: backwards;
            animation-fill-mode: backwards;
}
.grey-image {
    filter: gray; /* IE6-9 */
    -webkit-filter: grayscale(1); /* Google Chrome, Safari 6+ & Opera 15+ */
    filter: grayscale(1); /* Microsoft Edge and Firefox 35+ */
    width: 150px;
}

.scroll { 
    padding: 30px 0px;
    display: flex;
    overflow: hidden;
    -webkit-user-select: none;
            user-select: none;
    justify-content: space-between;
}

.scroll div  {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-shrink: 0;
    -webkit-animation: scroll-horizontal 10s linear infinite;
            animation: scroll-horizontal 10s linear infinite;
}

@-webkit-keyframes scroll-horizontal {
    to {
        -webkit-transform: translateX(-100%);
                transform: translateX(-100%);
    }
}

@keyframes scroll-horizontal {
    to {
        -webkit-transform: translateX(-100%);
                transform: translateX(-100%);
    }
}
.div-shadow {
    box-shadow: 1px 1px 10px #bbb;
    border: 0;
}

.about-text {
    font-family: 'Work Sans';
    display: block;
    overflow: auto;
    text-wrap: balance;
    font-size: 1em;
}

.image {
    height: 400px;
}
.service-card {
    width: 330px;
    background-color: white;
    box-shadow: 0px 1px 20px #ddd;
}

.works-page {
    background-color: rgb(250, 250, 250);
}
.blog-text {
    width: 60%;
    margin: auto;
    line-height:2;
    margin-top: 20px;
}
.blog-title {
    position: relative;
    text-align: center;
    background-color: white;
    padding: 1.9rem 1.25rem;
    width: 80%;
    margin: 0 auto;
    margin-top: -200px;
}

.blog-title-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-end;
}

.blog-container {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-end;
    height: calc(50vh);
}

.blog-card {
    max-width: 300px;
    padding: 0;
}

.blog-card a {
    text-decoration: none;
    color: #212529;
}
.blog-card a:hover {
    opacity: 80%;
}

.blog-card-image {
    width: 100%;
    height: 100%;
}

.blog-card-body {
    padding: 0;
}

.blog-page {
    background-color: rgb(250, 250, 250);
    min-height: 70vh;
}
