.card-div {
    display: flex;
    align-items: stretch;
}

.testimonial-box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: white;
    width: 400px;
    border-radius: 10px;
    padding: 20px 30px;
    box-shadow: 5px 5px 50px #e2e2e2;
    min-height: 320px;
}

.star-div {
    padding: 10px 0px;
    height: 70px;
}

.star {
    margin: 5px;
    width: 30px;
    height: 30px;
}

.testimonial-text {
    display: flex;
    text-align: justify;
    padding: 0px 10px;
    margin-top: 5px;
    font-size: 14px;
    letter-spacing: 1px;
}

.testimonial-footer {
    display: flex;
    align-self: baseline;
    padding-left: 10px;
    align-items: center;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: bold;
    color: grey;
}

@keyframes smallStar{
    0% {
        width: 10px;
        height: 10px;
    }

    100% {
        width: 30px;
        height: 30px;
    }
}

@keyframes smallStar-reverse{
     100% {
        width: 30px;
        height: 30px;
    }
    0% {
        width: 10px;
        height: 10px;
    }
}

.star-animation {
    animation: smallStar 3s;
    animation-delay: 0s;
    animation-fill-mode: backwards;
}


.star-animation-1 {
    animation: smallStar 3s;
    animation-delay: 400ms;
    animation-fill-mode: backwards;
}

.star-animation-2 {
    animation: smallStar 3s;
    animation-delay: 800ms;
    animation-fill-mode: backwards;
}

.star-animation-3 {
    animation: smallStar 3s;
    animation-delay: 1200ms;
    animation-fill-mode: backwards;
}

.star-animation-4 {
    animation: smallStar 3s;
    animation-delay: 1600ms;
    animation-fill-mode: backwards;
}