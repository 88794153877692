

/* Style the navigation menu */
.topnav {
    color: #25313C;
    font-weight: 500;
    letter-spacing: 1px;
    transition: 0.3s;
  }
  .height100 {
    height: 80px;
  }

.text-big {
  font-size: x-large;
}
  
  /* Hide the links inside the navigation menu (except for logo/home) */
  .topnav #myLinks {
    transition: 0.3s;
  }
 
  /* Style navigation menu links */
  .topnav a {
    color: #25313C;
    padding: 14px 16px;
    text-decoration: none;
    display: block;
    transition: 0.3s;
    /* background-color: #242423; */
  }
  
  /* Style the hamburger menu */
  .topnav a.icon {
    /* background: #242423; */
    display: block;
    position: absolute;
    right: 0;
    top: 0;
  }

  .mt100 {
    margin-top: 80px;
  }
  
  /* Style the active link (or home/logo) */
  .active {
    /* background-color:#242423; */
    color: #25313C;
  }


  .navDiv {
    z-index: 999;
    position: fixed;
    top:0;
    width: 100%;
  }
   
  .menu {
    background-color: #E6DED3;
  }

  @media only screen and (max-width: 600px) { 
    /* Style the navigation menu */
    .topnav {
    color: #25313C;
    font-weight: 500;
    letter-spacing: 1px;
    transition: 0.3s;
    padding: 10px;
  }
}

.text-menu {
  text-transform: uppercase;
  color: #686868;
  font-size: 14px;
  font-weight: 600;
}