.portofolio-image {
    object-fit: scale-down;
    max-height: 350px;
    /* max-width: 50%; */
    transition-property: margin-top max-width;
    transition-timing-function: ease-in-out;
    transition-duration: 3s;
    position: relative;
    z-index: 1;
  }
  .transformed-state {
    margin-top: 0px;
    max-width: 100%;
    transition-property: margin-top max-width;
    transition-timing-function: ease-in-out;
    transition-duration: 3s;
  }
  
  .delay-1 {
    transition-delay: 0.5s;
  }

  .delay-2 {
    transition-delay: 1s;
  }

  .delay-3 {
    transition-delay: 1.5s;
  }

  .left-image {
    height: 250px;
    margin-right: -90px;
    opacity: 0.7;
  }

  .right-image {
    height: 250px;
    margin-left: -90px;
    opacity: 0.7;
  }

  .citi-btn {
    background-color: #86BDC9;
    border-radius: 5px;
    padding: 10px 15px;
    color: white;
    text-decoration: none;
  }

  .citi-btn:hover {
    color: white;
    background-color: #8FC3CE;
    text-decoration: none;
  }

  @media only screen and (max-width: 600px) { 
    /* Style the navigation menu */
    .portofolio-image {
      object-fit: scale-down;
      max-height: 350px;
      max-width: 90%;
      position: relative;
      z-index: 1;
    }
}