.blog-text {
    width: 60%;
    margin: auto;
    line-height:2;
    margin-top: 20px;
}
.blog-title {
    position: relative;
    text-align: center;
    background-color: white;
    padding: 1.9rem 1.25rem;
    width: 80%;
    margin: 0 auto;
    margin-top: -200px;
}

.blog-title-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-end;
}

.blog-container {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-end;
    height: calc(50vh);
}

.blog-card {
    max-width: 300px;
    padding: 0;
}

.blog-card a {
    text-decoration: none;
    color: #212529;
}
.blog-card a:hover {
    opacity: 80%;
}

.blog-card-image {
    width: 100%;
    height: 100%;
}

.blog-card-body {
    padding: 0;
}

.blog-page {
    background-color: rgb(250, 250, 250);
    min-height: 70vh;
}