.image {
    width: 400px;
    position: absolute;
    top: 0px;
    left: 0px;
}

.image-top {
    width: 400px;
    position: absolute;
    top: 100px;
    left: 150px;
}

.image-top-right {
    width: 400px;
    position: absolute;
    top: 0px;
    left: 150px;
}

.image-bottom-right {
    width: 400px;
    position: absolute;
    top: 100px;
    left: 0px;
}

.service-card {
    width: 330px;
    background-color: white;
    box-shadow: 0px 1px 20px #ddd;
}

.other-service-card {
    background-color: white;
    box-shadow: 0px 1px 20px #ddd;
}